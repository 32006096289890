// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { APIRequest } from '../classes';
import { APIRequestResources } from '../enums';
import { MRNSearchResult, MRNSave } from '../types';

@Injectable({
    providedIn: 'root',
})

export class MrnService  extends APIRequest{
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }

    findMRNs(params: any): Observable<APIResponse<MRNSearchResult[]>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'mrn/find',
        };
        return this.get<MRNSearchResult[]>(options);
    }    
    
    getMRNById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'mrn',
        };
        return this.get<any>(options);
    }

    createMRN(mrn: MRNSave): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"mrn"
        };
        return this.post<MRNSave>(mrn, options);
    }

    findMrnByNumber(mrnNumber: any): Observable<APIResponse<any>> {
        let params = {
            mrnType: "",
            mrnNumber: mrnNumber,
            start_date: "",
            end_date: -1,
        }
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'mrn/find',
        };

        return this.get<MRNSearchResult[]>(options);
    }
}