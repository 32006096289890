// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { JasperAuthStorageType, PoSearchResult, PurchaseOrderCreate, UserAuthStorageType } from '../types';
import { APIRequest } from '../classes';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class PurchaseOrderService extends APIRequest{
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }

    // Fetch All POs
    getAllpurchaseOrders(): Observable<APIResponse<PoSearchResult[]>> {
        const options: APIRequestOptions = {
            endpoint:"purchase-order"
        };
        return this.get<PoSearchResult[]>(options);
    }

    getPurchaseOrderById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"purchase-order",
            suffix: id
        };
        return this.get<PoSearchResult[]>(options);
    }

    // Filter PO
    findPurchaseOrders(params: any): Observable<APIResponse<PaginationResponse<any>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'purchase-order/find', // Using the 'find' endpoint to get a specific book
        };
        return this.get<any>(options);
    }

    createPurchaseOrder(purchaseOrder: PurchaseOrderCreate): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"purchase-order"
        };
        return this.post<PurchaseOrderCreate>(purchaseOrder, options);
    }

    updatePurchaseOrder(id: string, purchaseOrder: PurchaseOrderCreate): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"purchase-order",
            suffix: id
        };
        return this.patch<PurchaseOrderCreate>(purchaseOrder, options);
    }

    getPurchaseOrderReport(id: string): Observable<any> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'purchase-order/report'
        };

        let sessionStorageData = sessionStorage.getItem('jspAuthData');
        let jasperAuthData: JasperAuthStorageType = {
            JSESSIONID: null,
            userLocale: null
        }
        if (sessionStorageData) {
            jasperAuthData = JSON.parse(sessionStorageData)
        }
        return this.getBlob(options, jasperAuthData.JSESSIONID ?? '');
    }

    updatePurchaseOrderStatus(id: string, purchaseOrderStatus: {status: string}): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"purchase-order/status",
            suffix: id
        };
        return this.patch<PurchaseOrderCreate>(purchaseOrderStatus, options);
    }
}