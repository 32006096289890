// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { APIRequestResources } from '../enums';
import { APIRequest, CachedAPIRequest } from '../classes';
import { CompanyProfileSearchResult } from '../types';

@Injectable({
    providedIn: 'root',
})
export class CompanyProfileService extends CachedAPIRequest{
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }

    // getCompanyProfiles(id:string): Observable<APIResponse<CompanyProfileSearchResult>> {
    //     const options: APIRequestOptions = {
    //         id:id
    //     };
    //     return this.companyProfileAPI.get<CompanyProfileSearchResult>(options,'performance');
    // }
    getCompanyProfiles(): Observable<APIResponse<CompanyProfileSearchResult>> {
        const options: APIRequestOptions = {
            endpoint:"company-profile",
            suffix:"1"
        };
        return this.get<CompanyProfileSearchResult>(options,'performance');
    }
}