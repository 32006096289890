// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { GRNSave, GRNSearchResult, JasperAuthStorageType} from '../types';
import { APIRequest } from '../classes';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class GrnService extends APIRequest{
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }
    // getAllGRNs(): Observable<PoSearchResult[]> {
    //     const options: APIRequestOptions = {};
    //     return this.get<PoSearchResult[]>(options);
    // }

    getGRNById(id:string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'grn',
            suffix: id
        };
        return this.get<any>(options);
    }

    findGRNs(params: any): Observable<APIResponse<GRNSearchResult[]>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'grn/find', // Using the 'find' endpoint to get a specific book
        };
        return this.get<GRNSearchResult[]>(options);
    }

    createGRN(grn: GRNSave): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"grn"
        };
        return this.post<GRNSave>(grn, options);
    }

    getGrnReport(id: string): Observable<any> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'grn/report'
        };

        let sessionStorageData = sessionStorage.getItem('jspAuthData');
        let jasperAuthData: JasperAuthStorageType = {
            JSESSIONID: null,
            userLocale: null
        }
        if (sessionStorageData) {
            jasperAuthData = JSON.parse(sessionStorageData)
        }
        return this.getBlob(options, jasperAuthData.JSESSIONID ?? '');
    }
}