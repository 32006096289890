import { HttpClient } from '@angular/common/http'
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ClearSearchQuery, SetSearchQuery } from 'src/app/store/search/search.actions';


export abstract class PageHistory {
    constructor(protected store: Store, protected ComponentName: string) { }

    searchQuery$ !: Observable<string>;
    pageInitialLoad: boolean= true

    storeSearchParams(initialQuery: string, query: any, ) {
        try {
            if(JSON.stringify(query) !== initialQuery){
                this.store.dispatch(new SetSearchQuery(this.ComponentName, JSON.stringify(query)));
            }else{
                this.clearSearchParams();
            }
        } catch (error) {
            console.log(error);

        }
    }

    clearSearchParams() {
        this.store.dispatch(new ClearSearchQuery(this.ComponentName));
    }
}