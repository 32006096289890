// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { APIRequestResources } from '../enums';
import { CachedAPIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class InvoiceTypeService extends CachedAPIRequest{
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI); 
    }

    getAllInvoiceTypes(): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            endpoint:"invoice-type"
        };
        return this.get<any[]>(options, 'performance');
    }

    getInvoiceTypeById(invoiceTypeId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"invoice-type",
            suffix : invoiceTypeId
        };
        return this.get<any>(options, 'freshness');
    }
}