import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class JasperService {
    private baseUrl = 'http://124.43.162.168:8080/jasperserver/rest_v2/reports/savinda_erp/po_report.pdf';

    constructor(private http: HttpClient) { }

    jasperLogin(username: string, password: string): Observable<any> {

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        let user = {
            j_username: username,
            j_password: password
        }

        return this.http.post(this.baseUrl, user,  { headers });
    }

    getReports(reportId: string, authToken: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            //   'Cookie': `${authToken}`,
        });

        const params = new HttpParams()
            .set('purchase_order_id', reportId)

        const options = { headers, params, withCredentials: true }

        return this.http.get(this.baseUrl, options);
    }
}

