// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { Packing } from '../types';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class PackingService extends APIRequest{
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    savePacking(newPacking: Packing): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "packing-list"
        };
        return this.post<any>(newPacking, options);
    }   

    findPacking(params: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "packing-list/find",
            params : params
        };
        return this.get<any>(options);
    }
    
    getPacking(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "packing-list",
            suffix: id
        };
        return this.get<any>(options);
    }
}