// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { InventoryGroupSearchResult } from '../types';
import { APIRequest, CachedAPIRequest } from '../classes';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class InventoryGroupService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    getAllInventoryGroups(): Observable<APIResponse<InventoryGroupSearchResult[]>> {
        const options: APIRequestOptions = {
            endpoint: "inventory-group"
        };
        return this.get<InventoryGroupSearchResult[]>(options, 'performance');
    }
}