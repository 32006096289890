import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { SalesOrderItem } from '../types';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class SalesOrderService extends APIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    saveSalesOrder(salesOrder: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order'
        };
        return this.post<any>(salesOrder, options);
    }

    updateSalesOrder(salesOrder: any, id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order',
            suffix: id
        };
        return this.patch<any>(salesOrder, options);
    }

    getSalesOrderById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order',
            suffix: id
        };
        return this.get<any>(options);
    }


    findSalesOrder(params: any): Observable<APIResponse<any>> {
        console.log(params);

        const options: APIRequestOptions = {
            endpoint: 'sales-order/find',
            params: params
        };
        return this.get<any>(options);
    }

    saveSalesOrderItems(salesOrderItem: { salesOrderId: number, salesOrderItems: SalesOrderItem[] }): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item'
        };
        return this.post<any>(salesOrderItem, options);
    }

    getSalesOrderItemById(salesOrderItemId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item',
            suffix: salesOrderItemId
        };
        return this.get<any>(options);
    }

    approveSalesOrderItem_bySupplyChain(salesOrderItemId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item/approve',
            suffix: salesOrderItemId
        };
        return this.patch<any>({}, options);
    }

    getSalesOrderItemApprovals(salesOrderItemId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item-get-signs',
            suffix: salesOrderItemId
        };
        return this.get<any>(options);
    }

    updateSalesOrderItem(salesOrderItem: any, salesOrderItemId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item',
            suffix: salesOrderItemId

        };
        return this.patch<any>(salesOrderItem, options);
    }

    findSalesOrderItems(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item/find',
            params: params
        };
        return this.get<PaginationResponse<any[]>>(options);
    }

    finalizeSalesOrderItem(SOI_id: any, cusReqDate: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item/print',
            suffix: SOI_id
        };
        return this.patch<any>(cusReqDate, options);
    }

    sendSalesOrderItemToArtwork(object: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/artwork-mail',
        };
        return this.post<any>(object, options);
    }

    updateSOIStatus(id: string, status: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item-status-update',
            suffix: id
        };
        return this.patch<any>(status, options);
    }
    updateSOIStatus_multiple(status: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'sales-order/sales-order-item-bulk-status-update',
        };
        return this.patch<any>(status, options);
    }

    // ======= BOM ==============
    saveBom(bomObject: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'bom',
        };
        return this.post<any>(bomObject, options);
    }

    getBom_bySalesOrderItemReferences(params: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'bom',
            params: params
        };
        return this.get<any>(options);
    }
    
    getBom_byJobNumber(params: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'bom/byJob',
            params: params
        };
        return this.get<any>(options);
    }    
    
    getBom_byNpdNumber(params: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'bom/byNpd',
            params: params
        };
        return this.get<any>(options);
    }
}