// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { Dispatch, InventoryTypeSearchResult, Invoice } from '../types';
import { APIRequest } from '../classes';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class DispatchService extends APIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI); 
    }

    save(dispatch: Dispatch): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'dispatch'
        };
        return this.post<any>(dispatch,options);
    }  
    
    find(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            endpoint:'dispatch/find',
            params:params
        };
        return this.get<PaginationResponse<any[]>>(options);
    }
    
    getById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'dispatch',
            suffix:id
        };
        return this.get<any>(options);
    }

    findDispatchReturns(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            endpoint:'dispatch/returnNote',
            params:params
        };
        return this.get<PaginationResponse<any[]>>(options);
    }

    saveDispatchReturn(dispatchReturn: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'dispatch/returnNote'
        };
        return this.post<any>(dispatchReturn,options);
    } 
}