// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { APIRequest, CachedAPIRequest } from '../classes';
import { APIRequestResources } from '../enums';
import { CustomerGetResult, CustomerCreate, Customer, APIRequestCacheStrategy } from '../types';

@Injectable({
    providedIn: 'root',
})
export class CustomerService extends CachedAPIRequest {  
    constructor(http: HttpClient) {
    super(http, APIRequestResources.SavindaAPI);
  }

    getAllCustomers(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'customer/find',
        };
        return this.get<PaginationResponse<any[]>>(options, 'performance');
    }

    getCustomerById(id: string, strategy: APIRequestCacheStrategy = 'performance'): Observable<APIResponse<CustomerGetResult>> {
        const options: APIRequestOptions = {
            endpoint:"customer",
            suffix: id
        };
        return this.get<CustomerGetResult>(options, strategy);
    }

    findCustomers(params: any, strategy: APIRequestCacheStrategy = 'performance'): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'customer/find',
        };
        return this.get<PaginationResponse<any[]>>(options, strategy);
    }

    createCustomer(customer: CustomerCreate): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"customer"
        };
        return this.post<CustomerCreate>(customer, options);
    }

    updateCustomer(customer: Customer, id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"customer",
            suffix: id
        };
        return this.patch<Customer>(customer, options);
    }

    updateCustomerStatus(customerStatus: any, id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'customer/status',
            suffix: id
        };
        return this.patch<any>(customerStatus, options);
    }

    getCustomerAllAddress(customerId: string, strategy: APIRequestCacheStrategy = 'performance'): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            endpoint: 'customer/address/find',
            suffix: customerId
        };
        return this.get<any>(options, strategy);
    }

    createCustomerAddress(address: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'customer/address'
        };
        return this.post<CustomerCreate>(address, options);
    }

    setDefaultInvoiceAddress(addressId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'customer/address/invoice',
            suffix: addressId
        };
        return this.patch<CustomerCreate>({}, options);
    }    
    
    setDefaultDeliveryAddress(addressId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'customer/address/delivery',
            suffix: addressId
        };
        return this.patch<CustomerCreate>({}, options);
    }    
    
    customerApproveEmail(emailObject: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'customer/approval-mail',
        };
        return this.post<CustomerCreate>(emailObject, options);
    }
}