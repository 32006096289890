import { Injectable } from "@angular/core";
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class DetermineJobType {

  private flexoJobs = [{ id: 6, code: "FLX", name: "Flexo" }]
  private digitalJobs = [{ id: 2, code: "DIG", name: "Digital printing" }]
  private tradingJobs = [{ id: 5, code: "TRA", name: "Trading" }]
  private offsetJobs = [
    { id: 1, code: "PKG", name: "Packaging" },
    { id: 3, code: "BOK", name: "Book" },
    { id: 4, code: "CAL", name: "Calender" },
    { id: 7, code: "TEN", name: "Tender" },
    { id: 8, code: "ICTN", name: "Inner cartoon" },
    { id: 9, code: "LAB", name: "Label" },
    { id: 10, code: "STC", name: "Sticker" },
  ]


  getJobGroup(id: number): string | null {
    if (this.flexoJobs.some(job => job.id === id)) {
      return "flexo";
    } else if (this.digitalJobs.some(job => job.id === id)) {
      return "digital";
    } else if (this.tradingJobs.some(job => job.id === id)) {
      return "trading";
    }else if (this.offsetJobs.some(job => job.id === id)) {
      return "offset";
    }
    return null; // If ID doesn't match any group
  }
}