// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class AuthAPIService extends APIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    getUserInfo(): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'auth',
        };
        return this.get<any>(options);
    }    
    
    getAccessToken_usingrefreshToken(): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: '/auth/refresh',
        };
        return this.post<any>({}, options);
    }

    // getPermitedUrls(): Observable<any[]> {
    //     const options = {
    //       endpoint: 'auth',
    //     };
    
    //     // Replace this with your desired array
    //     const allowedRoutes: any[] = [
    //       { path: 'dashboard', subRoutes: ['search', 'create'] },
    //       { path: 'inventory', subRoutes: ['search', 'create'] },
    //     ];
    
    //     // Return the array as an Observable
    //     return of(allowedRoutes);
    //   }
}