import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { NpdSaveObject, SalesOrderItem } from '../types';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class NpdService extends APIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    saveNpd(npdItem: NpdSaveObject): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'npd'
        };
        return this.post<any>(npdItem, options);
    }

    getNpdById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'npd',
            suffix: id
        };
        return this.get<any>(options);
    }


    findNpd(params: any): Observable<APIResponse<PaginationResponse<any[]>>>  {
        console.log(params);

        const options: APIRequestOptions = {
            endpoint: 'npd/find',
            params: params
        };
        return this.get<PaginationResponse<any[]>>(options);
    }

    updateNpd(npdId: string,npdItem: NpdSaveObject): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'npd',
            suffix: npdId
        };
        return this.patch<any>(npdItem, options);
    }

    approveNpd(id: string, newStatus: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'npd/approve',
            suffix: id
        };
        return this.patch<any>(newStatus,options);
    }   

    updateNpdStatus(id: string, newStatus: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'npd/status',
            suffix: id
        };
        return this.patch<any>(newStatus,options);
    }

}