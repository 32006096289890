// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { SupplierCreate, SupplierSearchResult } from '../types';
import { APIRequestResources } from '../enums';
import { CachedAPIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class SupplierService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    getAllSuppliers(): Observable<APIResponse<SupplierSearchResult[]>> {
        const options: APIRequestOptions = {
            endpoint: "supplier"
        };
        return this.get<SupplierSearchResult[]>(options, 'performance');
    }

    getAllSupplierById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: "supplier"
        };
        return this.get<any>(options, 'freshness');
    }

    findSuppliers(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'supplier/find',
        };
        return this.get<PaginationResponse<any[]>>(options, 'performance');
    }

    createSupplier(supplier: SupplierCreate): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "supplier"
        };
        return this.post<SupplierCreate>(supplier, options);
    }

    updateSupplier(supplier: SupplierCreate, id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: "supplier"
        };
        return this.patch<SupplierCreate>(supplier, options);
    }
}