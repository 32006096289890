// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class BarcodeAPIService extends APIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    getBarcode(text:any): Observable<any> {
        const options: APIRequestOptions = {
            params:text,
            endpoint: 'barcode',
        };
        return this.getBlobImg(options);
    }
}