import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { APIRequest, CachedAPIRequest } from '../classes';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class QuotationService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    saveQuotation(newQuotation: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'quotation'
        };
        return this.post<any>(newQuotation, options);
    }

    updateQuotation(newQuotation: any, id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'quotation'
        };
        return this.patch<any>(newQuotation, options);
    }

    findQuotation(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'quotation/find',
        };
        return this.get<PaginationResponse<any[]>>(options,'freshness');
    }    
    
    findQuotationWithBreackdowns_byCustomer(params: any): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'quotation/export',
        };
        return this.get<any[]>(options,'freshness');
    }

    getQuotationById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'quotation'
        };
        return this.get<any>(options, 'performance');
    }

    getAllQuotations(): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'quotation'
        };
        return this.get<any>(options, 'freshness');
    }

    finalizeQuotation(quotationId: string, status: {quotationStatus: string}): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'quotation/finalize',
            suffix: quotationId
        };
        return this.patch<any>(status, options);
    }
}