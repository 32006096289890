import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { APIRequest } from '../classes';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class SalesInqueryService extends APIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    saveSalesInquery(newSalesInquery: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "sales-inquiry"
        };
        return this.post<any>(newSalesInquery, options);
    }

    updateSalesInquery(id: string, newSalesInquery: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "sales-inquiry",
            suffix: id
        };
        return this.patch<any>(newSalesInquery, options);
    }

    findSalesInquery(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'sales-inquiry/find',
        };
        return this.get<PaginationResponse<any[]>>(options);
    }

    getSalesInqueryById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "sales-inquiry",
            suffix: id
        };
        return this.get<any>(options);
    }

    addSalesInqueryMaterial(newMaterial: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "sales-inquiry/sales-inquiry-material",
        };
        return this.post<any>(newMaterial, options);
    }
    addSalesInqueryBreackdown(newBreackdown: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "sales-inquiry/sales-inquiry-breakdown",
        };
        return this.post<any>(newBreackdown, options);
    }

    removeSalesInqueryMaterial(id: string, materialID: number): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "sales-inquiry/sales-inquiry-material",
            suffix: id,
        };
        return this.patch<any>({
            "salesInquiryMaterialId": materialID
        }, options);
    }
    removeSalesInqueryBreackdown(id: string, breackdownID: number): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "sales-inquiry/sales-inquiry-breakdown",
            suffix: id,
        };
        return this.patch<any>({
            "salesInquiryBreakdownId": breackdownID
        }, options);
    }

    saveProductDevelopment(newDevelopment: any, breackdownId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: breackdownId,
            endpoint: "sales-inquiry/update-development",
        };
        return this.patch<any>(newDevelopment, options);
    }

    finalizeSalesInquery(inqueryId: string): Observable<APIResponse<any>> {
        console.log(inqueryId);

        const options: APIRequestOptions = {
            suffix: inqueryId,
            endpoint: "sales-inquiry/finalize",
        };
        return this.patch<any>({}, options);
    }
}