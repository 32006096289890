// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { APIRequestResources } from '../enums';
import { APIRequest, CachedAPIRequest } from '../classes';
import { DepartmentSearchResult } from '../types';

@Injectable({
    providedIn: 'root',
})
export class DepartmentService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }

    getAllDepartments(): Observable<APIResponse<DepartmentSearchResult[]>> {
        const options: APIRequestOptions = {
            endpoint:"department"
        };
        return this.get<DepartmentSearchResult[]>(options,'performance');
    }
}