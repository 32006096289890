// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { Employee, EmployeeCategory, EmployeeCategoryCreate, EmployeeCreate } from '../types';
import { APIRequestResources } from '../enums';
import { APIRequest, CachedAPIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    findAllEmployees(): Observable<APIResponse<any[]>> {
        let params = { 'page_number': -1, 'items_per_page': 0 }
        const options: APIRequestOptions = {
            endpoint: "employee",
            params: params,
        };
        // return this.get<any[]>(options, 'performance');
        return this.get<any[]>(options, 'performance');
    }

    findEmployees(params: any): Observable<APIResponse<PaginationResponse<any[]>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'employee/find'
        };
        return this.get<PaginationResponse<any[]>>(options, 'freshness');
    }

    findEmployeeByID(id: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "employee",
            suffix: id
        };
        return this.get<any>(options, 'performance');
    }

    createEmployee(employee: EmployeeCreate): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "employee"
        };
        return this.post<EmployeeCreate>(employee, options);
    }

    updateEmployee(id: string, employee: Employee): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: "employee/employee"
        };
        return this.put<EmployeeCreate>(employee, options);
    }

    getAllEmpCategories(): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            endpoint: 'employee/category'
        };
        return this.get<any[]>(options, 'performance');
    }

    createEmployeeCategory(employeeCategory: EmployeeCategoryCreate): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'employee/category'
        };
        return this.post<EmployeeCategoryCreate>(employeeCategory, options);
    }

    updateEmployeeCategory(employeeCategory: EmployeeCategory, id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'employee/category'
        };
        return this.put<EmployeeCategory>(employeeCategory, options);
    }

    findEmployeeCategoryByID(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'employee/category'
        };
        return this.get<any>(options, 'performance');
    }

}