import { Injectable } from "@angular/core";
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class FileExporter {


  exportExcelFile(data: any[], fileName: string) {
    // const data = [
    //   ['Name', 'Age', 'Email'],
    //   ['John Doe', 30, 'john.doe@example.com'],
    //   ['Jane Smith', 25, 'jane.smith@example.com']
    // ];

    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // // Define your style array with each column and its style object
    // const styleArray = [
    //   {
    //     column: 'C', // Column to style
    //     styles: {
    //       alignment: { horizontal: 'center', vertical: 'middle' },
    //       font: { name: 'Arial', sz: 12, bold: true, color: { rgb: 'FF0000' } },
    //       fill: { fgColor: { rgb: 'FFFF00' } },
    //       border: {
    //         top: { style: 'thin', color: { rgb: '000000' } },
    //         bottom: { style: 'thin', color: { rgb: '000000' } },
    //         left: { style: 'thin', color: { rgb: '000000' } },
    //         right: { style: 'thin', color: { rgb: '000000' } }
    //       },
    //       numFmt: "$#,##0.00"
    //     }
    //   },
    //   {
    //     column: 'E', // Another column with similar or different styles
    //     styles: {
    //       alignment: { horizontal: 'right', vertical: 'middle' },
    //       font: { name: 'Calibri', sz: 10, bold: false, color: { rgb: '0000FF' } },
    //       fill: { fgColor: { rgb: 'FFDD99' } },
    //       border: {
    //         top: { style: 'thin', color: { rgb: '000000' } },
    //         bottom: { style: 'thin', color: { rgb: '000000' } },
    //         left: { style: 'thin', color: { rgb: '000000' } },
    //         right: { style: 'thin', color: { rgb: '000000' } }
    //       },
    //       numFmt: "0.00"
    //     }
    //   }
    //   // Add more objects for each column and style you want
    // ];

    // // Apply each style to the corresponding column in the worksheet
    // styleArray.forEach(({ column, styles }) => {
    //   Object.keys(ws).forEach(cell => {
    //     if (cell.startsWith(column)) {
    //       ws[cell].s = styles;
    //     }
    //   });
    // });


    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${fileName}.xlsx`);

  }

  getCurrentDate() {
    let date = new Date()
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
  }


}