import { Injectable } from "@angular/core";
import { TaxService } from "../services";

@Injectable({
    providedIn: 'root',
})
export class CalculateTotalTax {
    constructor(
        private taxService: TaxService) {
        this.getTaxGroups()
    }


    private vatPercentage = 0
    private nbtPercentage = 0
    private ssclPercentage = 0

    private getTaxGroups() {
        this.taxService.getAllTaxes().subscribe(
            (response) => {
                let data = response.data
                console.log(data);
                
                const VAT = data.find((item) => item.taxCode === "VAT");
                this.vatPercentage = VAT.taxPercentage

                const NBT = data.find((item) => item.taxCode === "NBT");
                this.nbtPercentage = NBT.taxPercentage                
                
                const SSCL = data.find((item) => item.taxCode === "SSCL");
                this.ssclPercentage = SSCL.taxPercentage
            },
            (error) => {
                console.log(error);
            }
        );
    }

    calculateTotalTax(taxType: string, salesValue: number) {
        if (taxType === "VAT" || taxType === "SVAT") {
            return this.calculateTax_Vat(salesValue)
        }
        else if (taxType === "SSCL") {
            return this.calculateTax_SSCL(salesValue)
        }
        else if (taxType === "NBT") {
            return this.calculateTax_Nbt(salesValue)
        }
        else if (taxType === "VAT-NBT") {
            return this.calculateTax_Vat_Nbt(salesValue)
        }
        else {
            return 0
        }
    }

    private calculateTax_Vat(salesValue: number) {
        let vatTaxValue = salesValue * this.vatPercentage / 100;
        return vatTaxValue
    }

    private calculateTax_Nbt(salesValue: number) {
        let nbtTaxValue = salesValue * this.nbtPercentage / 100;
        return nbtTaxValue
    }

    private calculateTax_SSCL(salesValue: number) {
        console.log("==================", salesValue,  this.ssclPercentage);
        
        let ssclTaxValue = salesValue * this.ssclPercentage / 100;
        return ssclTaxValue
    }

    private calculateTax_Vat_Nbt(salesValue: number) {
        let nbtTaxValue = salesValue * this.nbtPercentage / 100;
        let vatTaxValue = (salesValue + nbtTaxValue) * this.vatPercentage / 100;
        let totalTaxValue = nbtTaxValue + vatTaxValue
        return totalTaxValue
    }
}