// book.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { CurrencySearchResult } from '../types';
import { CachedAPIRequest } from '../classes';
import { HttpClient } from '@angular/common/http';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class CurrencyService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }

    getAllCurrencies(): Observable<APIResponse<CurrencySearchResult[]>> {
        const options: APIRequestOptions = {
            endpoint:"currency"
        };
        return this.get<CurrencySearchResult[]>(options, 'performance');
    }

    getExchangeRateById(datePLUSid: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            suffix: datePLUSid,
            endpoint:'currency/find'
        };
        return this.get<any>(options, 'freshness');
    }
}