// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { UnitsResult } from '../types';
import { CachedAPIRequest } from '../classes';
import { APIRequestResources } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class UnitService extends CachedAPIRequest{
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }

    getAllUnits(): Observable<APIResponse<UnitsResult[]>> {
        const options: APIRequestOptions = {
            endpoint:"unit"
        };
        return this.get<UnitsResult[]>(options,'performance');
    }
}