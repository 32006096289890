import {InvoiceService} from "../services";

export enum APIRequestResources {
    IdentityService = 'identity',
    UsersService = 'identity/users',
    RolesService = 'identity/roles',
    GroupsService = 'identity/groups',
    ResourcesService = 'identity/resources',
    ApplicationService = 'application',
    SavindaAPI = "",

    BankService = 'bank',
    CustomerService = 'customer',
    CustomerReceiptService = 'customer-receipt',
    CustomerTransactionService = 'customer-transaction',
    InvoiceService = 'invoice'

}
