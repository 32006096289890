// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse, PaginationResponse } from '../interfaces';
import { APIRequestCacheStrategy, InventoryItemSave, InventoryItemSearchResult } from '../types';
import { APIRequestResources } from '../enums';
import { APIRequest, CachedAPIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class InventoryItemService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    getAllInventoryItems(strategy: APIRequestCacheStrategy = 'freshness'): Observable<APIResponse<InventoryItemSearchResult[]>> {
        const options: APIRequestOptions = {
            endpoint: "inventory-item"
        };
        return this.get<InventoryItemSearchResult[]>(options, strategy);
    }

    getInventoryItem(id: string, strategy: APIRequestCacheStrategy = 'freshness'): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "inventory-item",
            suffix: id
        };
        return this.get<InventoryItemSearchResult[]>(options, strategy);
    }

    findInventoryItems(params: any, strategy: APIRequestCacheStrategy = 'freshness'): Observable<APIResponse<PaginationResponse<InventoryItemSearchResult[]>>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'inventory-item/find',
        };
        return this.get<PaginationResponse<InventoryItemSearchResult[]>>(options, strategy);
    }

    createInventoryItems(inventoryItem: InventoryItemSave): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "inventory-item"
        };
        return this.post<InventoryItemSave>(inventoryItem, options);
    }

    updateInventoryItems(inventoryItem: InventoryItemSave, id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "inventory-item",
            suffix: id
        };
        return this.patch<InventoryItemSave>(inventoryItem, options);
    }

    // Check inventory code
    checkInventoryCode(params: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'inventory-item/exist',
            suffix: params,
        };
        return this.get<any>(options, 'freshness');
    }
}