import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { APIRequestResources } from '../enums';
import { APIRequest, CachedAPIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class TaxService extends CachedAPIRequest {
    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
    }

    getAllTaxes(): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            endpoint: "tax"
        };
        return this.get<any[]>(options, 'freshness');
    }

    getTax(taxId: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: "tax",
            suffix: taxId
        };
        return this.get<any>(options, 'freshness');
    }

    getAllTaxeGroups(): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            endpoint: "tax/tax-group"
        };
        return this.get<any[]>(options, 'performance');
    }
}